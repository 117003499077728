<template>
  <div class="modal-items" ref="modalBg">
    <div class="modal-items__wrap">
      <input
        class="modal-items__search"
        @input="checkSearchResults"
        v-model.trim="inputSearchValue"
        @keyup.enter="createNewItem"
        ref="inputModal"
        type="text"
        placeholder="Найти..."
      />
      <i class="icon-search"></i>
    </div>
    <div class="button-chose-all" @click="selectAllOption">
      <div
        class="option-checkbox"
        :class="{ 'option-checkbox_chosen': value.length === options.length }"
      >
        <i class="icon-check" v-if="value.length === options.length"></i>
      </div>
      {{ channelUsers ? "Выбрать все" : "Выбрать всех" }}
    </div>
    <OptionSelect
      v-for="(option, index) in options"
      :option="option"
      :index="index"
      :hoverIndex="hoverIndex"
      :value="value"
      :itemName="itemName"
      :key="option.id"
      v-if="!loader"
      @choseOption="pushOption"
      @deleteChoseOption="deleteOption"
    />
    <div class="empty-bg" v-if="!loader && !options.length">Ничего не найдено :(</div>
    <Loader v-if="loader" />
  </div>
</template>

<script>
import Loader from "@/components/Widgets/Loader2.0/Loader";
const OptionSelect = () => import("@/components/Widgets/Select/Select2.1/OptionSelect");

import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ModalSelect",
  mixins: [],
  props: [
    "value",
    "placeholder",
    "itemName",
    "entity",
    "actionEntity",
    "multiple",
    "maxCount",
    "createMode",
    "hiddenItems",
    "createEntity",
    "channelUsers",
    "is_deleted",
    "filterType",
    "isAdminRegulation",
    "isRegulation",
    "ispGroup"
  ],
  components: { Loader, OptionSelect },
  data() {
    return {
      options: [],
      loader: true,
      inputSearchValue: "",
      timerId: 0,
      hoverIndex: null,
      searchFindId: false,
      listenerEvent: null
    };
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.eventsOptions, false);
  },
  mounted() {
    this.$refs.inputModal.focus();
    window.addEventListener("keydown", this.eventsOptions, false);
    this.getEntityData();
  },
  methods: {
    eventsOptions(event) {
      if (event.key === "ArrowUp") {
        if (this.hoverIndex && this.hoverIndex > this.findUnChosenIndex()) {
          this.hoverIndex--;
        } else {
          this.findHoverIndex();
        }
      }
      if (event.key === "ArrowDown") {
        if (this.hoverIndex === this.options.length - 1) return false;
        if (this.hoverIndex !== null) {
          this.hoverIndex++;
        } else {
          this.findHoverIndex();
        }
      }
      if (event.key === "Enter") {
        if (this.hoverIndex !== null && this.options[this.hoverIndex]) {
          this.pushOption(this.options[this.hoverIndex]);
        }
      }
      if (event.key === "Backspace") {
        this.value.splice(this.value.length - 1, 1);
      }
      if (event.key === "Escape") {
        this.$root.$emit("hideSelectModal");
      }
    },
    getEntityData() {
      this.hoverIndex = null;
      httpClient({
        url: `/${this.entity}/${this.actionEntity}?_search={"${this.itemName}":"${this.inputSearchValue}"}`,
        method: "GET",
        params: {
          _count: 300,
          _with: this.ispGroup ? JSON.stringify(["member_users"]) : undefined,
          channel_users: this.channelUsers
            ? JSON.stringify([{ "users.id": this.getUserId }])
            : undefined,
          type: this.filterType ? this.filterType : undefined,
          is_active: this.entity === "User" ? JSON.stringify([1]) : undefined,
          is_deleted:
            this.entity === "ISPGroup" && !this.is_deleted
              ? false
              : this.entity === "ISPGroup" && this.is_deleted
              ? true
              : undefined,
          is_archived: this.entity === "Department" ? false : undefined,
          users:
            this.isRegulation && !this.isAdminRegulation
              ? { "users.id": this.getUserId }
              : undefined
        }
      })
        .then((response) => {
          this.options = response.data.data.items;
          this.loader = false;
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    createNewItem() {
      if (!this.createMode || !this.inputSearchValue) return false;
      httpClient({
        url: `/${this.entity}/${this.createEntity}`,
        method: "POST",
        data: [
          {
            name: this.inputSearchValue
          }
        ]
      })
        .then((response) => {
          this.pushOption(response.data.data);
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    selectAllOption() {
      if (this.value.length === this.options.length) {
        this.$emit("deleteAllOption");
      } else {
        this.$emit("pushAllOption", this.options);
      }
    },
    checkSearchResults() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.getEntityData();
      }, 500);
    },
    pushOption(option) {
      this.$emit("pushOptionToValue", option);
      this.inputSearchValue = "";
      this.getEntityData();
    },
    deleteOption(option) {
      this.$emit("deleteOptionToValue", option);
    },
    findHoverIndex() {
      for (let i in this.$refs.modalBg.children) {
        let child = this.$refs.modalBg.children[i];
        if (child.dataset.index) {
          this.hoverIndex = parseInt(child.dataset.index);
          break;
        }
      }
    },
    findUnChosenIndex() {
      for (let i in this.$refs.modalBg.children) {
        let child = this.$refs.modalBg.children[i];
        if (child.dataset.index) {
          return parseInt(child.dataset.index);
        }
      }
    }
  },
  watch: {
    hoverIndex() {
      for (let i in this.$refs.modalBg.children) {
        let child = this.$refs.modalBg.children[i];
        if (this.hoverIndex === parseInt(child.dataset.index)) {
          this.$refs.modalBg.scrollTop = child.offsetTop - child.offsetHeight;
        }
      }
    }
  },
  validations: {},
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.modal-items {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  z-index: 15;
  width: 100%;
  max-height: 260px;
  overflow-y: scroll;
  top: 100%;
  padding: 8px 4px 8px 4px;
  background-color: $bg-gray-75;
  transform: scale(0);

  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 14px;

  &__wrap {
    position: relative;
    width: 100%;
  }

  &__search {
    width: 100%;
    height: 40px;
    color: $color-text-primary-1;
    outline: none;
    flex-shrink: 0;
    padding: 10px 0 10px 20px;
    background: $bg-surface-light;
    border-radius: 40px;
    border: none;
    box-sizing: border-box;
  }
  &__search::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;
  }

  //&__search:focus {
  //  border: 1px solid $action-primary;
  //  padding: 9px 0 9px 19px;
  //}

  .icon-search {
    position: absolute;
    top: 13px;
    right: 20px;
    color: $icon-subdued;
    font-size: 17px;
  }

  .option-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border: 2px solid #d3d2e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 8px;

    .icon-check {
      color: #ffffff;
    }

    &_chosen {
      background-color: $action-primary;
      border: 2px solid $action-primary;
    }
  }

  .chosen-options-bg {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 6px;

    .chosen-options {
      display: flex;
      align-items: center;
      background-color: $action-primary;
      border-radius: 4px;
      padding: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      color: $action-secondary;
      margin-bottom: 6px;
      user-select: none;
      margin-right: 6px;
      .icon-cross {
        font-size: 10px;
        cursor: pointer;
        margin-left: 5px;
      }
      @media screen and (max-width: $xl) {
        height: auto;
      }
    }
  }
  .button-chose-all {
    display: flex;
    align-items: center;
    width: 100%;
    height: 34px;
    cursor: pointer;
    padding-left: 8px;
    margin-top: 10px;
    user-select: none;
    flex-shrink: 0;
  }
  .button-chose-all:hover {
    background: #f1effa;
    border-radius: 4px;
  }
  .empty-bg {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    color: $color-text-primary-2;
  }
  .loader-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
  }
}
</style>
